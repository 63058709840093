import { ON_SELECT } from '../actions/product'
import {FunctionalManager} from "../FunctionalManager";
import {Role} from '../user/Role';


export class base {

    static beforeReduct(state, action){
        console.info(this.name + "Reducter");
        console.info( 'action', action);
        console.info( 'statebefore', state);

    }
    /**
     * ТОчка входа
     * @param state
     * @param action
     */
    static call(state, action)
    {
        this.beforeReduct(state, action);

        const [ newState, heandled ] = this.reduct(state, action)

        this.afterReduct(newState, heandled);

        return  {...newState};
    }

    /**
     * Перегружаемый метод
     * @param state
     * @param action
     * @returns {(*|boolean)[]}
     */
    static reduct(state, action){
        let newState = state;

        let heandled = false;

        return [ newState, heandled ];
    }

    static afterReduct(newState, heandled){
        console.info( 'stateafter', newState);
        if (heandled)
            console.info("Has handled !!!");
        else
            console.info("no heandler");
    }
}