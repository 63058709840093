import {
  ON_CHANGE_VALUE,
  ON_UPDATE,
  ON_DOWNLOAD_PRODUCT_LIST,
  ON_DOWNLOAD_PRODUCT_LIST_FROM_API_V1,
  ON_SELECT
} from '../actions/product';
import { base } from './base';
import { defaultValues } from '../../UI/components/product/ProductPropsView';
import { Tree } from '../../UI/converters/product/Tree';
import { NONE, SUCCESS, FAIL } from '../../UI/components/product/CircularIntegration';

/**
 * Обработка событий связанных с операциями над продуктом
 * state.product.*
 */
export class product extends base {
  /**
   * Перегружаемый метод
   * @param state
   * @param action
   * @returns {(*|boolean)[]}
   */
  static reduct(state, action) {
    let newState = state;

    let heandled = false;

    switch (action['type']) {
      case ON_UPDATE:{
        const success = action['value'];
        if (success) {
          [newState, heandled] = [
            {
              ...newState,
              canSave: false,
              lastSaveState: SUCCESS
            },
            true
          ];
        }
        else{
          [newState, heandled] = [
            {
              ...newState,
              canSave: true,
              lastSaveState: FAIL
            },
            true
          ];
        }
        break;
      }

      case ON_SELECT: {
        const view = this.upDateView(action['value'], state);

        [newState, heandled] = [
          {
            ...newState,
            selectionKey: action['value'],
            selectionKeys: action['value'],
            view: view,
            canSave: false,
            lastSaveState: NONE
          },
          true
        ];
        break;
      }
      case ON_CHANGE_VALUE: {
        let view = state.view;
        const target = action['value'];
        /*
                        console.info('id', target.id);
                        console.info( 'value', target.value);
                        console.info(view);*/

        view[target.id] = target.value;

        [newState, heandled] = [
          {
            ...newState,
            view: view,
            canSave: true,
            lastSaveState: NONE
          },
          true
        ];
        break;
      }
      case ON_DOWNLOAD_PRODUCT_LIST: {
        [newState, heandled] = [{ ...newState, list: action['value'] }, true];
        break;
      }
      case ON_DOWNLOAD_PRODUCT_LIST_FROM_API_V1: {

        const data = Tree.fromApi_v1(action['value']);
        [newState, heandled] = [{ ...newState, list: data }, true];
        // [newState, heandled] = [{...newState, list: action['value']}, true];
        break;
      }
    }

    return [newState, heandled];
  }


  static upDateView(selectionKey, state) {
    /*        if (selectionKey != state.selectionKey)
                state = { ...state, selectionKey: selectionKey}
              //  this.setState({selectionKey: selectionKey});
            else
                return;*/

    if (selectionKey == '')
      return state;

    //    console.log(selectionKey);
    const source = state.list;

    const conponents = selectionKey.split('-');

    if (conponents.length != 2) {
      console.log('select category');
      return state;
    }

    //console.log(conponents);
    let c = conponents.shift();
    let result = source.find(function(item, index, array) {
      return item.key == c;
    });
    //console.log(result);

    c = conponents.shift();

    let product = result.children.find(function(item, index, array) {
      return item.key == selectionKey;
    });
    //  console.log(product);
    let data;
    if (true) {
      var clone = {}; // новый пустой объект
      for (var key in product.data) {
        clone[key] = product.data[key];
      }
      data = clone;
    } else {
      data = product.data;
    }

    // const data =  product.data;

    //    console.log(data);

    if (Object.keys(data).length == 0)
      return defaultValues;

    return data;
  }
}