import { SET_ROLE, ADD_TODO2, SET_ROUTS, SET_HASH, SET_TOKEN } from '../actions/system'
import {FunctionalManager} from "../FunctionalManager";
import {Role} from '../user/Role';
import {base} from "./base";
import {ON_SELECT} from "../actions/product";

export class system extends base {
    /**
     * Перегружаемый метод
     * @param state
     * @param action
     * @returns {(*|boolean)[]}
     */
    static reduct(state, action) {
        let newState = state;

        let heandled = false;

        switch (action['type'] ){
            case SET_ROLE: {
                if (state.role != action['value']) {
                    console.info("change");
                    const routs = FunctionalManager.createRouts(action['value'])
                    const menu = FunctionalManager.setActiveUserRole(action['value']);

                    let name = state.name;
                    let logged;

                    if (action['value'] == Role.Guest) {
                        name = 'Гость';
                        logged = false;
                    } else {
                        logged = true;
                    }

                    [newState, heandled] = [
                        {
                            ...state,
                            routs: routs,
                            menu: menu,
                            name: name,
                            logged: logged,
                            role: action['value'],
                            location: window.location.hash
                        },
                        true
                    ];
                } else {
                    console.info("no change");
                    [newState, heandled] = [{...state}, true];

                }
                break;
            }

            case SET_ROUTS: {
                [newState, heandled] = [{...state, menu: action['value']}, true];
                break;
            }

            case ADD_TODO2: {
                [newState, heandled] = [{...state, name: action['value']}, true];
                break;
            }

            case SET_HASH: {
                [newState, heandled] = [{...state, location: action['value']}, true];
                break;
            }

            case SET_TOKEN:{
                [newState, heandled] = [{...state, token: action['value']}, true];
                break;
            }
        }

        return [newState, heandled];
    }
}