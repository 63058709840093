import api from './api';

export class Product {
  getTree() {
    return api.get('Food').then(res => res.data);
  }

  update(product) {
    console.info(product);
    return api.get('Food').then(res => res.data);
  }
}