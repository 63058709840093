/*
 * action types
 */

export const ON_SELECT = 'ON_SELECT';
export const ON_CHANGE_VALUE = 'ON_CHANGE_VALUE';
export const ON_UPDATE = 'ON_UPDATE';
export const ON_DOWNLOAD_PRODUCT_LIST = 'ON_DOWNLOAD_PRODUCT_LIST';
export const ON_DOWNLOAD_PRODUCT_LIST_FROM_API_V1 = 'ON_DOWNLOAD_PRODUCT_LIST_FROM_API_V1';

/*
 * action creators
 */

export function onSelectionProduct(value) {
  return { type: ON_SELECT, value: value };
}

export function onChangeValue(value) {
  return { type: ON_CHANGE_VALUE, value: value };
}

export function onDownloadProductList(value, version = 0) {
  if (version == 0)
    return { type: ON_DOWNLOAD_PRODUCT_LIST, value: value };
  else
    return { type: ON_DOWNLOAD_PRODUCT_LIST_FROM_API_V1, value: value };
}

export function onFailUpdate() {
  return { type: ON_UPDATE, value: false };
}

export function onSuccessUpdate() {
  return { type: ON_UPDATE, value: true };
}