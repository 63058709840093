/*
 * action types
 */

export const SET_ROLE = 'SET_ROLE'
export const ADD_TODO2 = 'ADD_TODO2'
export const TOGGLE_TODO = 'TOGGLE_TODO'
export const SET_VISIBILITY_FILTER = 'SET_VISIBILITY_FILTER'
export const SET_ROUTS = 'SET_ROUTS'
export const SET_HASH = 'SET_HASH'
export const SET_TOKEN = 'SET_TOKEN'

/*
 * action creators
 */

export function onHashChange(value){
    return {type: SET_HASH, value: value}
}

export function setRouts(value) {
    return {type: SET_ROUTS, value: value}
}

export function setRole(value) {
    return {type: SET_ROLE, value: value}
}

export function setToken(value) {
    return {type: SET_TOKEN, value: value}
}

export function setName(value) {
    return {type: ADD_TODO2, value: value}
}

export function toggleTodo(index) {
    return { type: TOGGLE_TODO, index }
}

export function setVisibilityFilter(filter) {
    return { type: SET_VISIBILITY_FILTER, filter }
}