import React, { Fragment } from 'react';

import { PageTitle } from '../../layout-components';

import { ExampleWrapperSeamless } from '../../layout-components';

import FormsControlsBasic from '../../example-components/FormsControls/FormsControlsBasic';
import FormsControlsInputGroups from '../../example-components/FormsControls/FormsControlsInputGroups';
export default function FormsControls() {
  return (
    <Fragment>
      <PageTitle titleHeading="Продукты"  titleDescription="Окно поиска и редактирования продуктов"/>
      <ExampleWrapperSeamless sectionHeading="Basic">
        <FormsControlsBasic />
      </ExampleWrapperSeamless>
      <ExampleWrapperSeamless sectionHeading="Input groups">
        <FormsControlsInputGroups />
      </ExampleWrapperSeamless>
    </Fragment>
  );
}
