//import { Provider } from 'react-redux'
//import { connect } from 'react-redux'
import { combineReducers, createStore } from 'redux';
import { SET_ROLE, ADD_TODO2, SET_ROUTS, SET_HASH, onHashChange } from '../../BL/actions/system';
//import { LoginPage } from "../../UI/components/LoginPage";
//import { FunctionalManager } from "../../BL/FunctionalManager";
import themeReducter from '../../reducers/ThemeOptions';
import { system as systemReducter } from '../../BL/reducers/system';
import { product as productReducter } from '../../BL/reducers/product';
import { Role } from '../../BL/user/Role';
import { NONE } from '../../UI/components/product/CircularIntegration';

const initialState = {
  token: null,
  name: 'Гость',
  role: Role.Guest,
  logged: false,
  location: '#/',
  routs: [
    /*        {path: "/", component: LoginPage}*/
  ],
  menu: [],

  product: {
    view: [],
    canSave: false,
    lastSaveState: NONE
  }
};

/**
 * This function accepts the app state, and saves it to localStorage
 * @param state
 */
const saveStateToLocalStorage = (state) => {
  try {
    // Convert the state to a JSON string
    const serialisedState = JSON.stringify(state);

    // Save the serialised state to localStorage against the key 'app_state'
    window.localStorage.setItem('app_state', serialisedState);
  } catch (err) {
    // Log errors here, or ignore
  }
};

/**
 * Загрузка состяния приложения из локального хранилища браузера
 * @returns {any|undefined}
 */

const loadStateFromLocalStorage = () => {
  try {
    // Load the data saved in localStorage, against the key 'app_state'
    const serialisedState = window.localStorage.getItem('app_state');

    // Passing undefined to createStore will result in our app getting the default state
    // If no data is saved, return undefined
    if (!serialisedState) return undefined;

    // De-serialise the saved state, and return it.
    return JSON.parse(serialisedState);
  } catch (err) {
    // Return undefined if localStorage is not available,
    // or data could not be de-serialised,
    // or there was some other error
    return undefined;
  }
};

/**
 * Инициализация хранилища состояния приложения
 * @returns {Store<unknown, Action>}
 */

function init() {
  console.info('init store');
  //Загружаем
  const oldState = loadStateFromLocalStorage();

  const reducters = combineReducers({
      ThemeOptions: themeReducter,
      system: (state, action) => systemReducter.call(state, action),
      // product: (state, action) => productReducter.call(state, action),
      product: productReducter.call.bind(productReducter)
    }
  );

  const store = createStore(reducters, oldState ?? initialState);

  //Иницируем роут согластно сохраненному сотоянию
  if (store.getState()['location'])
    window.location = store.getState()['location'];

  return store;
}

/**
 * Инстанцирование экземпляра хранилища состояния приложения
 */

export const runTimeStore = init();

// eslint-disable-next-line no-unused-vars
const subscribe = runTimeStore.subscribe(() => {
  saveStateToLocalStorage(runTimeStore.getState());
});

/**
 * Обработчик изменения текущего роута
 */
window.onhashchange = function() {
  runTimeStore.dispatch(onHashChange(window.location.hash));
};
