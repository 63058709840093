import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green, red } from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import CheckIcon from '@material-ui/icons/Check';
import SaveIcon from '@material-ui/icons/Save';
import ErrorIcon from '@material-ui/icons/Error';

import { connect } from 'react-redux';
import { onSuccessUpdate, onFailUpdate } from '../../../BL/actions/product';
import { Product as ProductDAL } from '../../../DAL/Product';
import { runTimeStore } from '../../../DAL/service/runTimeStore';

export const NONE = 0;
export const SUCCESS = 1;
export const FAIL = -1;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center'
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative'
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700]
    }
  },
  buttonError: {
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700]
    }
  },
  fabProgress: {
    color: green[500],
    position: 'absolute',
    top: -6,
    left: -6,
    zIndex: 1
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
}));

function CircularIntegration(props) {
  /**
   * Активность кнопок
   */
  const { disabled, success } = props;

  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);

  const buttonClassname = clsx({
    [classes.buttonSuccess]: success == 1,
    [classes.buttonError]: success == -1
  });

  const productService = new ProductDAL();

  const handleButtonClick = () => {
    if (success == SUCCESS || loading) return;
    else {
      setLoading(true);
      productService
        .update(runTimeStore.getState().product.view)
        .then(
          reason => {
            runTimeStore.dispatch(onSuccessUpdate());
          },
          reject => {
            console.info('reject');
            runTimeStore.dispatch(onFailUpdate());
          }
        )
        .finally(() => setLoading(false));
    }
  };

  const Icon =
    success === 1 ? (
      <CheckIcon />
    ) : success === 0 ? (
      <SaveIcon />
    ) : (
      <ErrorIcon />
    );

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <Fab
          disabled={disabled && success != SUCCESS}
          aria-label="save"
          color="primary"
          className={buttonClassname}
          onClick={handleButtonClick}>
          {Icon}
        </Fab>
        {loading && (
          <CircularProgress size={68} className={classes.fabProgress} />
        )}
      </div>
      <div className={classes.wrapper}>
        <Button
          variant="contained"
          color="primary"
          className={buttonClassname}
          disabled={loading || disabled && success != SUCCESS}
          onClick={handleButtonClick}>
          {props.name}
        </Button>
        {loading && (
          <CircularProgress size={24} className={classes.buttonProgress} />
        )}
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  disabled: !state.product.canSave,
  success: state.product.lastSaveState
});

const mapDispatchToProps = dispatch => ({
  handleButtonClick: value => {
    console.info(value);
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CircularIntegration);
