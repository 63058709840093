import { createStore, combineReducers } from 'redux';
import reducers from '../reducers';
import { runTimeStore } from '../DAL/service/runTimeStore';

import { system as systemReducter } from '../BL/reducers/system';
import { product as productReducter } from '../BL/reducers/product';
/*
export default function configureStore() {
  return createStore(
    combineReducers({
      ...reducers,
      system: (state, action) => systemReducter.call(state, action),
      product: productReducter.call.bind(productReducter)
    }),
    {}
  );
}*/
/*export default function configureStore() {
  return createStore(
    combineReducers({
      ...reducers
    }),
    {}
  );
}*/


export default function configureStore() {
  return runTimeStore;
}
