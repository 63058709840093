import React, {Component} from 'react';


const reas = [
        {
            "key": "0",
            "label": "Бакалея",
            "data": "Documents Folder",
            "icon": "",
            "children": [{
                "key": "0-0",
                "label": "Дрожжи",
                "data": {
                    "name": "Дрожжи",
                    "snpin_group": "Бакал",
                    "group": "Drecyzirb",
                    "union": "г.",
                    "proce_on_100_g": "200",
                    "beloc": 12,
                    "jir": 32,
                    "uglevod": 32,
                    "vitamin_c": 12,
                    "energy": 1233
                },
                "icon": "",
                "children": []
            }
            ]
        }
]

const BELOC = 0;
const UGLI = 1;
const ENERGY = 2;
const FEAT = 3;
const VIT = 4;
/**
 * Обеспечивает приеведение структуры данных к виду ожидаемому компонентом
 */
export class Tree {



    static child2(item, index, array){
        return {label: item.Name};
    }

    static child(parentIndex){
        return function(item, index, array){

          //  item.Nutrient['BELOC'].Value
            const data = {
                "name": item.Name,
                "snpin_group": "Бакал",
                "group": "Drecyzirb",
                "union": "г.",
                "proce_on_100_g": "200",
                "beloc": item.Nutrient[BELOC].Value,
                "jir": item.Nutrient[FEAT].Value,
                "uglevod": item.Nutrient[UGLI].Value,
                "vitamin_c": item.Nutrient[VIT].Value,
                "energy": item.Nutrient[ENERGY].Value
            };

            return {key: `${parentIndex}-${index}`, label: item.Name, data: data};
        };
    }



    static parent(item, index, array){
        const children = item['Food'].map(this.child(index));

        return { key: index.toString(), label: item.Name, data: null, children: children};
        // возвращается новое значение вместо элемента
    }

    static fromApi_v1(data){
        console.info("tree::fromApi_v1");

        console.info(reas);
        console.info(data);

        let result = data['FoodGroup'].map(this.parent.bind(this));

        console.info(result);

        return result;
        return reas;
        throw true;
        return data
    }
}


