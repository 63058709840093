import {Role} from './user/Role';
import {runTimeStore} from '../DAL/service/runTimeStore';
import { lazy } from 'react';
const LandingPage = lazy(() => import('../example-pages/LandingPage'));
/*import {Dashboard} from "../UI/components/dashboard/Dashboard";
import {DevelDashboard} from "../UI/components/dashboard/DevelDashboard";
import {AdminDashboard} from "../UI/components/dashboard/AdminDashboard";
import {UserDashboard} from "../UI/components/dashboard/UserDashboard";
import {FormsDemo} from "../UI/components/FormsDemo";
import {SampleDemo} from "../UI/components/SampleDemo";
import {DataDemo} from "../UI/components/DataDemo";
import {Product} from "../UI/components/Product";
import {Product2} from "../UI/components/Product2";
import {PanelsDemo} from "../UI/components/PanelsDemo";
import {OverlaysDemo} from "../UI/components/OverlaysDemo";
import {MenusDemo} from "../UI/components/MenusDemo";
import {MessagesDemo} from "../UI/components/MessagesDemo";
import {ChartsDemo} from "../UI/components/ChartsDemo";
import {MiscDemo} from "../UI/components/MiscDemo";
import {EmptyPage} from "../UI/components/EmptyPage";
import {Documentation} from "../UI/components/Documentation";
import {setRole, setRouts} from "./actions/system";*/
/**
 * Настройка интерфейса в соответстви с доступностью компонентов для конкретной роли
 */
export class FunctionalManager {

    static setActiveUserRole(role) {
        let newMenu;

        switch (role) {
            case Role.User : {
                newMenu = [
                    {
                        label: 'Dashboard', icon: 'pi pi-fw pi-home', to: '/user-dashboard' //, command: () => {
                        //      window.location = '#/devel-dashboard'
                        //  }
                    },
                    {
                        label: 'Справочники', icon: 'pi pi-fw pi-cog',
                        items: [
                            {label: 'Продукты', icon: 'pi pi-fw pi-bars', to: '/product'},
                            {label: 'Продукты2', icon: 'pi pi-fw pi-bars', to: '/product2'},
                            {label: 'Рецаптуры', icon: 'pi pi-fw pi-bars', to: '/receipt'},
                            {label: 'Перспективное меню', icon: 'pi pi-fw pi-bars', to: '/future_menu'}
                        ]
                    }
                ];
                window.location = '#/user-dashboard';
                break;
            }
            case Role.Admin : {
                newMenu = [];
                window.location = '#/admin-dashboard';
                break;
            }
            case Role.Developer : {
                newMenu = FunctionalManager.createMenu();
                window.location = '#/devel-dashboard';
                break;
            }
            default: {
                newMenu = [];
            //    window.location = '#/';
            }
        }

        return newMenu;
    }

    static createRouts(role){
        let  routs;

        switch (role) {
            case Role.Developer : {
                routs = [
/*                    {path: "/", component: LoginPage},
                    {path: "/dashboard", component: Dashboard},
                    {path: "/devel-dashboard", component: DevelDashboard},
                    {path: "/admin-dashboard", component: AdminDashboard},
                    {path: "/user-dashboard", component: UserDashboard},
                    {path: "/forms", component: FormsDemo},
                    {path: "/sample", component: SampleDemo},
                    {path: "/data", component: DataDemo},
                    {path: "/product", component: Product},
                    {path: "/panels", component: PanelsDemo},
                    {path: "/overlays", component: OverlaysDemo},
                    {path: "/menus", component: MenusDemo},
                    {path: "/messages", component: MessagesDemo},
                    {path: "/charts", component: ChartsDemo},
                    {path: "/misc", component: MiscDemo},
                    {path: "/empty", component: EmptyPage},
                    {path: "/documentation", component: Documentation},*/
                ]
                break;
            }
            default: {
                routs = [
                    {path: "/", component: LandingPage}
                ];
            }
        }



        return routs;
    }

    static createMenu() {
        console.log('createMenu');
        const menu = [
            {
                label: 'Dashboard', icon: 'pi pi-fw pi-home', to: '/devel-dashboard' //, command: () => {
              //      window.location = '#/devel-dashboard'
              //  }
            },

            {
                label: 'Справочники', icon: 'pi pi-fw pi-cog',
                items: [
                    {label: 'Продукты', icon: 'pi pi-fw pi-bars', to: '/product'},
                    {label: 'Продукты2', icon: 'pi pi-fw pi-bars', to: '/product2'},
                    {label: 'Рецаптуры', icon: 'pi pi-fw pi-bars', to: '/receipt'},
                    {label: 'Перспективное меню', icon: 'pi pi-fw pi-bars', to: '/future_menu'}
                ]
            },

            {
                label: 'Menu Modes', icon: 'pi pi-fw pi-cog',
                items: [
                    {label: 'Static Menu', icon: 'pi pi-fw pi-bars',  command: () => this.app.setState({layoutMode: 'static'}) },
                    {label: 'Overlay Menu', icon: 'pi pi-fw pi-bars',  command: () => this.app.setState({layoutMode: 'overlay'}) }
                ]
            },
            {
                label: 'Menu Colors', icon: 'pi pi-fw pi-align-left',
                items: [
                    {label: 'Dark', icon: 'pi pi-fw pi-bars',  command: () => this.app.setState({layoutColorMode: 'dark'}) },
                    {label: 'Light', icon: 'pi pi-fw pi-bars',  command: () => this.app.setState({layoutColorMode: 'light'}) }
                ]
            },
            {
                label: 'Components', icon: 'pi pi-fw pi-globe', badge: '9',
                items: [
                    {label: 'Sample Page', icon: 'pi pi-fw pi-th-large', to: '/sample'},
                    {label: 'Forms', icon: 'pi pi-fw pi-file', to: '/forms'},
                    {label: 'Data', icon: 'pi pi-fw pi-table', to: '/data'},
                    {label: 'Panels', icon: 'pi pi-fw pi-list', to: '/panels'},
                    {label: 'Overlays', icon: 'pi pi-fw pi-clone', to: '/overlays'},
                    {label: 'Menus', icon: 'pi pi-fw pi-plus', to: '/menus'},
                    {label: 'Messages', icon: 'pi pi-fw pi-spinner',to: '/messages'},
                    {label: 'Charts', icon: 'pi pi-fw pi-chart-bar', to: '/charts'},
                    {label: 'Misc', icon: 'pi pi-fw pi-upload', to: '/misc'}
                ]
            },
            {
                label: 'Template Pages', icon: 'pi pi-fw pi-file',
                items: [
                    {label: 'Empty Page', icon: 'pi pi-fw pi-circle-off', to: '/empty'}
                ]
            },
            {
                label: 'Menu Hierarchy', icon: 'pi pi-fw pi-search',
                items: [
                    {
                        label: 'Submenu 1', icon: 'pi pi-fw pi-bookmark',
                        items: [
                            {
                                label: 'Submenu 1.1', icon: 'pi pi-fw pi-bookmark',
                                items: [
                                    {label: 'Submenu 1.1.1', icon: 'pi pi-fw pi-bookmark'},
                                    {label: 'Submenu 1.1.2', icon: 'pi pi-fw pi-bookmark'},
                                    {label: 'Submenu 1.1.3', icon: 'pi pi-fw pi-bookmark'},
                                ]
                            },
                            {
                                label: 'Submenu 1.2', icon: 'pi pi-fw pi-bookmark',
                                items: [
                                    {label: 'Submenu 1.2.1', icon: 'pi pi-fw pi-bookmark'},
                                    {label: 'Submenu 1.2.2', icon: 'pi pi-fw pi-bookmark'}
                                ]
                            },
                        ]
                    },
                    {
                        label: 'Submenu 2', icon: 'pi pi-fw pi-bookmark',
                        items: [
                            {
                                label: 'Submenu 2.1', icon: 'pi pi-fw pi-bookmark',
                                items: [
                                    {label: 'Submenu 2.1.1', icon: 'pi pi-fw pi-bookmark'},
                                    {label: 'Submenu 2.1.2', icon: 'pi pi-fw pi-bookmark'},
                                    {label: 'Submenu 2.1.3', icon: 'pi pi-fw pi-bookmark'},
                                ]
                            },
                            {
                                label: 'Submenu 2.2', icon: 'pi pi-fw pi-bookmark',
                                items: [
                                    {label: 'Submenu 2.2.1', icon: 'pi pi-fw pi-bookmark'},
                                    {label: 'Submenu 2.2.2', icon: 'pi pi-fw pi-bookmark'}
                                ]
                            }
                        ]
                    }
                ]
            },
            {label: 'Documentation', icon: 'pi pi-fw pi-question', command: () => {window.location = "#/documentation"}},
            {label: 'View Source', icon: 'pi pi-fw pi-search', command: () => {window.location = "https://github.com/primefaces/sigma"}}
        ];

        return menu;
    }
}