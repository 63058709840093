import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { InputText } from 'primereact/inputtext';
import { RadioButton } from 'primereact/radiobutton';
import PropTypes from 'prop-types';

import { onChangeValue } from '../../../BL/actions/product';
import { Card, Divider, TextField, Typography } from '@material-ui/core';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import CircularIntegration from './CircularIntegration';

export const defaultValues = {
  selectionKey: null,

  name: '',
  snpin_group: '',
  group: '',
  union: '',
  proce_on_100_g: '',
  beloc: '',
  jir: '',
  uglevod: '',
  vitamin_c: '',
  energy: ''
};

class ProductPropsView extends Component {
  static defaultProps = defaultValues;

  static propTypes = {
    onChange: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      ExpansionPanelExpanded: false
    };
  }

  handleChange(event) {
    this.setState({
      ExpansionPanelExpanded: !this.state.ExpansionPanelExpanded
    });
    // setExpanded(isExpanded ? panel : false);
  };

  render() {
    return (
      <div className="p-grid">
        <TextField
          fullWidth
          className="m-2"
          id="name"
          label="Наименование"
          rowsMax="4"
          value={this.props.name}
          onChange={(e) => this.props.onChange(e.target)}
        />
        <TextField
          fullWidth
          className="m-2"
          id="beloc"
          label="Белки г."
          rowsMax="4"
          value={this.props.beloc}
          onChange={(e) => this.props.onChange(e.target)}
          variant="outlined"
        />

        <TextField
          fullWidth
          className="m-2"
          id="jir"
          label="Жиры г."
          rowsMax="4"
          value={this.props.jir}
          onChange={(e) => this.props.onChange(e.target)}
          variant="outlined"
        />
        <TextField
          fullWidth
          className="m-2"
          id="uglevod"
          label="Углеводы г."
          rowsMax="4"
          value={this.props.uglevod}
          onChange={(e) => this.props.onChange(e.target)}
          variant="outlined"
        />
        <TextField
          fullWidth
          className="m-2"
          id="vitamin_c"
          label="Витамин С. мг"
          rowsMax="4"
          value={this.props.vitamin_c}
          onChange={(e) => this.props.onChange(e.target)}
          variant="outlined"
        />

        <TextField
          fullWidth
          className="m-2"
          id="energy"
          label="Энергетическая ценность, ккал"
          rowsMax="4"
          value={this.props.energy}
          onChange={(e) => this.props.onChange(e.target)}
          variant="outlined"
        />

        <ExpansionPanel
          expanded={this.state.ExpansionPanelExpanded}
          onChange={e => this.handleChange(e)}>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header">
            <Typography>дополнительно</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div className="p-grid">
              <TextField
                fullWidth
                className="m-2"
                id="snpin_group"
                label="Группа по СанПин"
                multiline
                rowsMax="4"
                value={this.props.snpin_group}
                onChange={(e) => this.props.onChange(e.target)}
                variant="filled"
              />
              <TextField
                fullWidth
                className="m-2"
                id="group"
                label="Группа"
                multiline
                rowsMax="4"
                value={this.props.group}
                onChange={(e) => this.props.onChange(e.target)}
                variant="filled"
              />
              <TextField
                fullWidth
                className="m-2"
                id="union"
                label="Ед.изм"
                rowsMax="4"
                value={this.props.union}
                onChange={(e) => this.props.onChange(e.target)}
              />
              <TextField
                fullWidth
                className="m-2"
                id="proce_on_100_g"
                label="Цена за 100г"
                rowsMax="4"
                value={this.props.proce_on_100_g}
                onChange={(e) => this.props.onChange(e.target)}
                variant="outlined"
              />
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <div className="p-col-12" style={{ padding: 10 + 'px', justifyContent: 'center', display: 'flex' }}>
          <CircularIntegration name="Сохранить" />
        </div>
      </div>
    );
  }
}

export default connect(
  state => ({
    selectionKey: state.product.selectionKeys,

    name: state.product.view.name,
    snpin_group: state.product.view.snpin_group,
    group: state.product.view.group,
    union: state.product.view.union,
    proce_on_100_g: state.product.view.proce_on_100_g,
    beloc: state.product.view.beloc,
    jir: state.product.view.jir,
    uglevod: state.product.view.uglevod,
    vitamin_c: state.product.view.vitamin_c,
    energy: state.product.view.energy
  }),
  dispatch => (dispatch, ownProps) => {
    return {
      onChange: (value) => {
        console.info(value.value);
        dispatch(onChangeValue(value));
      }
    };
  }
)(ProductPropsView);